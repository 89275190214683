import React from "react"
import { Link } from "gatsby-plugin-intl"

const ConsultingEN = () => <>
  <h2>Technical <span>support</span></h2>
  <p className="bigger-text text-center"> HIBRID is a company with many years of experience in the anti-corrosion
    coating market we know ,
    how extensive the field of protection of steel constructions against corrosion is. The team of
    HIBRID’s advisors will be happy to share their knowledge with you. To contact our technical support
    team please click <Link to={"/kontakt"}>here.</Link></p>
  <p>The technical staff of HIBRID form: experienced spray painters and people directly associated with
    the production process, quality control inspectors, civil engineers, construction, , energy production,
    mechanical and production processes engineers.</p>
  <hr />
  <p>Atmospheric corrosiveness categories and examples of typical environments:</p>
  <table width="100%">
    <tbody>
    <tr>
      <td rowSpan="2"><strong>Corrosion risk Category</strong></td>
      <td rowSpan="2"><strong>Zinc loss in first year (µm)</strong></td>
      <td colSpan="2"><strong>Examples of typical environment</strong></td>
    </tr>
    <tr>
      <td>External</td>
      <td>Internal</td>
    </tr>
    <tr>
      <td>C1 (very low)</td>
      <td>Less than 0.1</td>
      <td><strong>-</strong></td>
      <td>Insulating building, relative humidity less than 60%</td>
    </tr>
    <tr>
      <td>C2 (low)</td>
      <td>0.1 to 0.7</td>
      <td>Slightly polluted atmosphere, dry climate (for example rural areas).</td>
      <td>Non-insulated building with temporary water condensation (for example a warehouse or sports hall).
      </td>
    </tr>
    <tr>
      <td>C3 (medium)</td>
      <td>0.7 to 2.1</td>
      <td>Urban or industrial atmosphere with low level of SO2 pollution or coastal areas with low salinity</td>
      <td>Buildings with high relative humidity and impurities in the air (for example breweries, laundries or
        dairies.
      </td>
    </tr>
    <tr>
      <td>C4 (high)</td>
      <td>2.1 to 4.2</td>
      <td>Industrial or coastal environments with low salinity</td>
      <td>Swimming pools, chemical factories</td>
    </tr>
    <tr>
      <td>C5I (very high-industrial)</td>
      <td>4.2 to 8.4</td>
      <td>Industrial environments with considerable humidity and an agressive atmosphere</td>
      <td>Buildings or areas with almost constant
        water condensation and high levels of
        pollutions
      </td>
    </tr>
    <tr>
      <td>C5M (very high-maritime)</td>
      <td>4.2 to 8.4</td>
      <td>Coastal areas with high salinity</td>
      <td>Buildings or areas with almost constant
        water condensation and high levels of
        pollutions
      </td>
    </tr>
    </tbody>
  </table>
  <h4>Anticorrosion protection systems for hot-dip galvanized steel:</h4>
  <p>For hot-dip galvanized steel structures, we recommend a corrosion protection system approved by the Research
    Institute of Roads and Bridges:</p>
  <table width="100%">
    <tbody>
    <tr>
      <td><strong>Layer</strong></td>
      <td><strong>Product name</strong></td>
      <td><strong>Coating thickness</strong></td>
    </tr>
    <tr>
      <td>&gt;Technological coating</td>
      <td><strong>2H2 Zandinol Washprimer</strong></td>
      <td>&gt;10-20 μm</td>
    </tr>
    <tr>
      <td>&gt;Undercoat</td>
      <td><strong>C25 Monopox Metalcoat ZL-70</strong></td>
      <td>&gt;80-250 μm</td>
    </tr>
    <tr>
      <td>&gt;Topcoat</td>
      <td><strong>D48 Polyfinish MU-DL</strong></td>
      <td>&gt;80-110 μm</td>
    </tr>
    </tbody>
  </table>
  <h4>Fire retardant systems:</h4>
  <p>For Fire retardant systems with fire resistance class R15 - R90 (for open and closed profiles, rectangular) and
    R15-R60 (for closed and round profiles) we recommend a system approved by the ITB (Building Research
    Institute).</p>
  <table width="100%">
    <tbody>
    <tr>
      <td><strong>Layer</strong></td>
      <td><strong>Product name</strong></td>
      <td><strong>Coating thickness</strong></td>
    </tr>
    <tr>
      <td>&gt;Undercoat</td>
      <td><strong>C55 Monopox FP-Primer</strong></td>
      <td>&gt;70-100 μm</td>
    </tr>
    <tr>
      <td>&gt;Basic coating (swelling)</td>
      <td><strong>Flame Stal Fire Proof Solvent</strong></td>
      <td>&gt;Diffrent</td>
    </tr>
    <tr>
      <td>&gt;Topcoat</td>
      <td><strong>D48 Polyfinish MU-DL</strong></td>
      <td>&gt;60-80 μm</td>
    </tr>
    </tbody>
  </table>
</>

export default ConsultingEN