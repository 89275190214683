import React from "react"
import { Link } from "gatsby-plugin-intl"

const ConsultingDE = () => <>
  <h2>Technische <span>Beratung</span></h2>
  <p className="bigger-text text-center">Als Unternehmen mit mehrjähriger Erfahrung in der
    Antikorrosionsbeschichtungverstehen wir wie umfangreich die Metallschutzbranche ist. Die umfassendeprojektbezogene
    Kundenberatung ist für uns selbstverständlich. Unser Beraterteamsteht Ihnen gerne zur Verfügung.Zwecks
    Kontaktaufnahme klicken Sie bitte <Link to={"/kontakt"}>hier.</Link></p><p className="text-center">Hier ein
  Überblick über interessante Informationen rundum Korrosionsschutz</p>
  <hr />
  <table width="100%">
    <tbody>
    <tr>
      <td rowSpan="2"><strong>Korrosivitätskategorie</strong></td>
    </tr>
    <tr>
      <td></td>
      <td>Beispiele</td>
    </tr>
    <tr>
      <td>C1</td>
      <td>Unbedeutend</td>
      <td>Nur innen: GeheizteGebäude mit neutralenAtmosphären</td>
    </tr>
    <tr>
      <td>C2</td>
      <td>Gering</td>
      <td>Ländliche Bereiche,ungeheizte Gebäude, indenen Kondensationauftreten kann, z. B.Lager, Sporthallen</td>
    </tr>
    <tr>
      <td>C3</td>
      <td>Mäßig</td>
      <td>Stadt- undIndustrieatmosphäre mitmäßigerLuftverunreinigung,Küstenbereiche mitgeringer
        Salzbelastung,Produktionsräume mithoher Luftfeuchte undetwas Luftverunreinigung(z.
        B.Lebensmittelherstellung,Wäschereien, Brauereien)
      </td>
    </tr>
    <tr>
      <td>C4</td>
      <td>Stark</td>
      <td>Industrielle Bereiche,Küstenbereiche mitmäßiger Salzbelastung,Chemieanlagen,Schwimmbäder</td>
    </tr>
    <tr>
      <td>C5I</td>
      <td>Sehr stark (Industrie)</td>
      <td>Industrielle Bereiche mithoher Luftfeuchte undaggressiver Atmosphäre</td>
    </tr>
    <tr>
      <td>C5M</td>
      <td>Sehr stark (Meer)</td>
      <td>Küsten- undOffshorebereich mit hoherSalzbelastung, Gebäudemit nahezu ständigerKondensation und mitstarker
        Luftverunreinigung
      </td>
    </tr>
    </tbody>
  </table>
  <h4>Beschichtungssysteme für die feuerverzinkte Stahlkonstruktionen:</h4><p>Für feuerverzinkte Stahlkonstruktionen
  empfehlen wir folgendes System:</p>
  <table width="100%">
    <tbody>
    <tr>
      <td><strong>Schicht</strong></td>
      <td><strong>Produkt</strong></td>
      <td><strong>Schichtdicke</strong></td>
    </tr>
    <tr>
      <td>&gt;primäre Beschichtung</td>
      <td><strong>2H2 Zandinol Washprimer</strong></td>
      <td>&gt;10-20 μm</td>
    </tr>
    <tr>
      <td>&gt;Grundbeschichtung</td>
      <td><strong>C25 Monopox Metalcoat ZL-70</strong></td>
      <td>&gt;80-250 μm</td>
    </tr>
    <tr>
      <td>&gt;Deckbeschichtung</td>
      <td><strong>D48 Polyfinish MU-DL</strong></td>
      <td>&gt;80-110 μm</td>
    </tr>
    </tbody>
  </table>
  <h4>Brandschutzbeschichtungssysteme:</h4><p>Für Brandschutzbeschichtungssysteme mit der Feuerwiderstandsklasse
  R15-R90(für offene und geschlossene rechtwinklige Profile) sowie für R15-R60(geschlossene, runde Profile)
  empfehlen wir folgendes System:</p>
  <table width="100%">
    <tbody>
    <tr>
      <td><strong>Schicht</strong></td>
      <td><strong>Produkt</strong></td>
      <td><strong>Schichtdicke</strong></td>
    </tr>
    <tr>
      <td>&gt;Grundbeschichtung</td>
      <td><strong>C55 Monopox FP-Primer</strong></td>
      <td>&gt;70-100 μm</td>
    </tr>
    <tr>
      <td>&gt;Dämmbeschichtung</td>
      <td><strong>Flame Stal Fire Proof Solvent</strong></td>
      <td>&gt;Różna</td>
    </tr>
    <tr>
      <td>&gt;Deckbeschichtung</td>
      <td><strong>D48 Polyfinish MU-DL</strong></td>
      <td>&gt;60-80 μm</td>
    </tr>
    </tbody>
  </table>
  <p>Trotz Anwendung der standardisierten oben beschriebenen Systeme wird jedeKundenanfrage individuell betrachtet.
    Das Beschichtungssystem wird anhand vonverschiedenen Faktoren ( Konstruktionsart, Flächenvorbereitung,
    Umgebung,erwartete Haltbarkeit ) angepasst.</p>
</>

export default ConsultingDE