import React from "react"
import { useIntl } from "gatsby-plugin-intl"
import "../scss/main.scss"
import bannerImage from '../../static/images/banner/doradzctwo.jpg';
import ServicePage from "../layouts/ServicesPage"
import ConsultingContent from "../content/Consulting"
export default function MeltingPage() {
  const intl = useIntl()
  return <ServicePage bannerImage={bannerImage}>
    <ConsultingContent lang={intl.locale} />
  </ServicePage>
}
