import React from "react"

const ConsultingPL = () => <>
  <h2>Doradztwo <span>techniczne</span></h2>
  <p className="bigger-text text-center"> Jako firma z wieloletnim doświadczeniem na rynku powłok antykorozyjnych
    wiemy, jak szeroką
    dziedziną jest ochrona konstrukcji stalowych przed korozją. Zespół doradców HIBRID chętnie podzieli
    się z Państwem swoją wiedzą. Aby skontaktować się z naszym zespołem pomocy technicznej,
    kliknij <a href="kontakt.html">tutaj.</a></p>
  <p>Personel techniczny HIBRID tworzą: doświadczeni malarze/lakiernicy i osoby bezpośrednio związane
    z procesem produkcyjnym, inspektorzy kontroli jakości, inżynierowie budownictwa, energetyki ,
    mechaniki i procesów produkcji.</p>
  <hr />
  <p>Przykłady środowisk w poszczególnych klasach korozyjności atmosfery:</p>
  <table width="100%">
    <tbody>
    <tr>
      <td rowSpan="2"><strong>Klasa korozyjności</strong></td>
      <td colSpan="2"><strong>Przykłady środowisk</strong></td>
    </tr>
    <tr>
      <td>Na zewnątrz</td>
      <td>Wewnątrz</td>
    </tr>
    <tr>
      <td>C1 – bardzo mała</td>
      <td><strong>-</strong></td>
      <td>Ogrzewane budynki z czystą atmosferą, np. biura, sklepy, szkoły, hotele</td>
    </tr>
    <tr>
      <td>C2 – mała</td>
      <td>Atmosfery w małym stopniu zanieczyszczone. Głównie tereny wiejskie.</td>
      <td>Nieogrzewane budynki, w których może mieć miejsce kondensacja, np. magazyny, hale sportowe</td>
    </tr>
    <tr>
      <td>C3 – średnia</td>
      <td>Atmosfery miejskie i przemysłowe, średnie zanieczyszczenie tlenem siarki (IV). Obszary
        przybrzeżne o małym zasoleniu.
      </td>
      <td>Pomieszczenia produkcyjne o dużej wilgotności&nbsp;i pewnym zanieczyszczeniu powietrza, np.
        zakłady spożywcze, pralnie, browary, mleczarnie
      </td>
    </tr>
    <tr>
      <td>C4 – duża</td>
      <td>Obszary przemysłowe i przybrzeżne&nbsp;o średnim zasoleniu</td>
      <td>Zakłady chemiczne, pływalnie, stocznie remontowe łodzi i statków</td>
    </tr>
    <tr>
      <td>C5I – bardzo&nbsp;duża (przemysłowa)</td>
      <td>Obszary przemysłowe o dużej wilgotności&nbsp;i agresywnej atmosferze</td>
      <td>Budowle lub obszary z prawie ciągłą kondensacją&nbsp;i dużym zanieczyszczeniem</td>
    </tr>
    <tr>
      <td>C5M – bardzo duża (morska)</td>
      <td>Obszary przybrzeżne i oddalone od brzegu w głąb morza o dużym zasoleniu</td>
      <td>Budowle lub obszary z prawie ciągłą kondensacją&nbsp;i dużym zanieczyszczeniem.</td>
    </tr>
    </tbody>
  </table>
  <h4>Systemy na stal ocynkowaną ogniowo:</h4>
  <p>Dla konstrukcji stalowych ocynkowanych ogniowo zalecamy system zabezpieczeń antykorozyjnych zatwierdzony przez
    Instytut Badawczy Dróg i Mostów::</p>
  <table width="100%">
    <tbody>
    <tr>
      <td><strong>Warstwa</strong></td>
      <td><strong>Nazwa produktu</strong></td>
      <td><strong>Grubość powłoki</strong></td>
    </tr>
    <tr>
      <td>&gt;Powłoka technologiczna</td>
      <td><strong>2H2 Zandinol Washprimer</strong></td>
      <td>&gt;10-20 μm</td>
    </tr>
    <tr>
      <td>&gt;Powłoka podkładowa</td>
      <td><strong>C25 Monopox Metalcoat ZL-70</strong></td>
      <td>&gt;80-250 μm</td>
    </tr>
    <tr>
      <td>&gt;Powłoka nawierzchniowa</td>
      <td><strong>D48 Polyfinish MU-DL</strong></td>
      <td>&gt;80-110 μm</td>
    </tr>
    </tbody>
  </table>
  <h4>Systemy ognioochronne:</h4>
  <p>Dla systemów ognioochronnych o klasie odporności ogniowej R15 – R90 (dla profili otwartych i
    zamkniętych, prostokątnych) oraz R15-R60 (dla profili zamkniętych, okrągłych)zalecamy systemy
    zatwierdzone przez ITB (Instytut Techniki Budowlanej).</p>
  <table width="100%">
    <tbody>
    <tr>
      <td><strong>Warstwa</strong></td>
      <td><strong>Nazwa produktu</strong></td>
      <td><strong>Grubość powłoki</strong></td>
    </tr>
    <tr>
      <td>&gt;Powłoka podkładowa</td>
      <td><strong>C55 Monopox FP-Primer</strong></td>
      <td>&gt;70-100 μm</td>
    </tr>
    <tr>
      <td>&gt;Powłoka zasadnicza (pęczniejąca)</td>
      <td><strong>Flame Stal Fire Proof Solvent</strong></td>
      <td>&gt;Różna</td>
    </tr>
    <tr>
      <td>&gt;Powłoka nawierzchniowa</td>
      <td><strong>D48 Polyfinish MU-DL</strong></td>
      <td>&gt;60-80 μm</td>
    </tr>
    </tbody>
  </table>
  <p>Pomimo przyjętych standardowych zestawów malarskich opisanych powyżej, każde zapytanie ofertowe jest
    rozpatrywane indywidualnie. Dobór zabezpieczenia antykorozyjnego jest zależny od wielu czynników, m.in
    rodzaju konstrukcji, sposobu przygotowania powierzchni, rodzaju środowiska czy oczekiwanej trwałości
    systemu.</p>
</>

export default ConsultingPL