import React from "react"
import ConsultingDE from "./consulting.de"
import ConsultingEN from "./consulting.en"
import ConsultingPL from "./consulting.pl"

const ConsultingContent = ({ lang }) => {
  if (lang === "de") {
    return <ConsultingDE />
  } else if (lang === "en") {
    return <ConsultingEN />
  }
  return <ConsultingPL />
}

export default ConsultingContent